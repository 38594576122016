@import "custom";
// @import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@400;500&display=swap');
.App {
  // font-family: Arial, Helvetica, sans-serif;
  font-family: 'Montserrat', sans-serif;
  // font-family: 'Oswald', sans-serif;
  // font-family: 'Barlow Condensed', sans-serif;
  min-height: 100vh;
}
.main-content {
 
  min-height: 69vh;
}
h1, h2{
  // font-family: 'Barlow Condensed', sans-serif;
}
//Removes arrow buttons from number input fields. There is accompanying scroll-blocking code in ./assets/commonFunctions
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.pointer {
  &:hover {
    cursor: pointer;
  }
}
.border-dark {
  border: 1px solid black;
}
.hover-blue {
  &:hover {
    color: blue;
  }
}

.bg-grey {
    background-color: #c7c7c7 !important;
}
.bg-light-grey {
  background-color: #d3d3d3 !important;

}
.text-faded-green {
  color: rgb(110, 156, 110) !important;
}
.hover-bg-grey:hover {
  background-color: #c7c7c7 !important;
}
.sign-embedded {
  // position: relative;
  width: 100%;
  min-height: 80vh;
  // padding-bottom: 100%;
}
.capitalize-first-letter {
    text-transform: capitalize;
}

#file-iframe {
  width: 100%;
  height: 100%;
}

.height-100 {
  height: 100% !important;
}
.faded {
  opacity: .5;
}

.help-cursor {
  cursor: help;
}
.bg-faded-grey {
  background-color: rgba(131, 131, 131, 0.349);
}
.red-border {
  border: 2px solid red;
}
.border-dotted-dark {
  border: 1px inset rgb(73, 73, 73);
}
#payment-status-column {
  width: 300px;
}
.border-bottom-dark {
	border-bottom: 2px dotted rgb(151, 151, 151);

}
.bg-hover-lighter:hover {
  background-color: lightgray;
}
.bg-hover-grey:hover {
  background-color: rgba(193, 193, 193, 0.556);
}
.left-border-primary {
  border-left: 3px solid rgb(0, 123, 255);
  padding-left: 30px;
}
.bg-very-light-grey {
  background-color: rgb(154, 154, 154);
}