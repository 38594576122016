.footer-container {
    background: linear-gradient(0deg, rgba(1,111,230,0.5192629815745393) 0%, rgba(248,249,250,1) 91%);
    min-height: 200px;
    box-sizing: border-box;

}

.footer-logo {
    height: 150px;
    
}
.footer-links {
    text-decoration: none;
}