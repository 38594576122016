.promise-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 400px;
    background-color: #ffffff ;
}
#promises {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    max-width: 1000px;
}
.promise-logos {
    height: 50px;
}