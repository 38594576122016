.milestone {
  background-color: white;
  border-bottom-left-radius: 10px;
  border: 1px solid grey;
  border-top: none;
  border-bottom-right-radius: 10px;
}
.milestone-head {
  // position: relative;

  background-color: rgb(233, 233, 233);
  border: 1px solid grey;
  border-radius: 10px;
  cursor: pointer;
}
.milestone-head-active {
  // position: relative;

  border-top-right-radius: 10px;
  border: 1px solid grey;
  border-bottom: none;
  border-top-left-radius: 10px;
  cursor: pointer;
  background-color: rgb(217, 230, 255);
}
.definition-blockquote {
  font-size: 1.4em;
  width: 100%;
  // margin:50px auto;
  // font-family:Open Sans;
  font-style: italic;
  color: #555555;
  padding: 0.2em 30px 0.9em 30px;
  border-left: 8px solid rgb(192, 169, 255);
  line-height: 1.6;
  position: relative;
  background: #ededed;

  ::before {
    font-family: Arial;
    // content: "\201C";
    color: #78a9c0;
    font-size: 4em;
    position: absolute;
    left: 0px;
    top: -10px;
  }
  ::after {
    content: "";
  }
  span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
  }
}
.edit-text-parent {
  position: relative;
  background-color: rgb(73, 8, 19);
  padding: 0;
  margin: 0;
  width: 100%;

}
.edit-text {
  position: absolute;
  top: -1.8em;
  // right: 50%;
  // text-align: center;
  background-color: white;
  color: red;
}
.original-text {
  position: absolute;
  top: -1.8em;
  // right: 50%;
  // text-align: center;
  background-color: white;
  // color: blue;
}
