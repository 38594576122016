#contact-page-container{
   min-height: 65vh;

}
.contact-container {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
    border-radius: 10px;
    p {
        margin: 0;
    }
}

.contact-info {
 transition: .5s;   
 &:hover {
     transform: scale(1.06);
     cursor: pointer;
 }
}
