#join-container {
  min-height: 300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background: rgb(58, 203, 255);
  background: linear-gradient(
    61deg,
    rgba(58, 203, 255, 1) 8%,
    rgba(2, 0, 198, 1) 100%
  );
 
}
