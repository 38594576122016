#search-container {
    width: 100%;
    min-height: 300px;
    background-size: cover;
    display: flex;
    align-items: center;
}

.search-col {
   display: flex;
   flex-direction: column;
   justify-content: center;
}

.search-form {
    background-color: rgba(255, 255, 255, 0.733);
}