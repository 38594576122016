
#logo-image {
    height: 70px;
}
#bridge-text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #4D4D4D;
}

#core-text {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: #016FE6;
}

.header-links {
    text-decoration: none;
&:hover {
    cursor: pointer;
}
}
#header-link {
    text-decoration: none;
}