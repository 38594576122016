@import url("https://fonts.googleapis.com/css2?family=Spline+Sans&display=swap");
.customer-pricing {
  background-color: #e9f0ff;
}
.price-card {
  font-family: "Spline Sans", sans-serif;
}
.subcontractor-pricing {
  background-color: rgb(199, 214, 243);
}
.general-contractor-pricing {
  background-color: rgb(50, 64, 146);
  color: white;
}
#small-text {
  font-size: 12px;
}
