#bio {
    text-align: justify;
}
#company-profile-header {
    text-transform: capitalize;
}

#company-image {
    max-height: 300px;
    width: 100%;
    object-fit: scale-down;
}
#insurance {
    text-align: right;
}
#insurance-value {
    text-align: left;
}
.company-spinner {
    height: 80px;
    width: 80px;
}

@media only screen and (max-width: 576px) {
    #insurance-value {
        text-align: center;
    }
    #insurance {
        text-align: center;
    }
}
