.entity-img {
 width: 100%;
}
.entity {
    background-color: white;
    p {
        margin: 0;
    }
}
@media only screen and (max-width: 767px) {
    .entity-img {
        width: 50%;
    }
}