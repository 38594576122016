
.escrow-card-img {
    height: 150px;
}

.escrow-arrows {
    font-size: 90px;
    font-weight: 900;
    transition: 1s;
}

@media only screen and (max-width: 992px) {
    .escrow-arrows {
        font-size: 60px;
        transform: rotate(90deg);
    }
}