#sponsored-header {
    font-weight: 600;
}

.company-logos {
    height: 60px;
}

@media only screen and (max-width: 576px) {
    .comp-name {
        text-align: center;
    }

}

